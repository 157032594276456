import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (

    
    <>
      <div className="menuSection wowmenu">
        <div className="container">
        <div className="row d-flex">
          <div className="col-md-12">
            <nav className="navbar">
          
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
           <img src="../img/logo.png" className="logo"/>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/ourservice"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Our Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
              </div>
           
            </nav>
             </div>
          </div>
          </div>
        </div>
    </>
  );
}

export default NavBar;
