import React from "react";

function TopHeader() {
    return (
        <>
       <div className="topHeader">
    <div className="container">
        <div className="row d-flex align-items-center">
            <div className="col-md-6 col-lg-4">
                <a href="mailto:contact@consultquark.com" className="whiteText">
            <i className="glyph-icon flaticon-open-email pe-1"></i>   contact@consultquark.com
            </a>
            </div>
            <div className="col-md-6 col-lg-8">
<ul className="socialIcon">
   <li>
        <a className="" target="blank" href="https://www.facebook.com/profile.php?id=100086482462173" title="Facebook">
            <i className="fa-brands fa-facebook-f"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="#" title="Pinterest">
            <i className="fa-brands fa-twitter"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="https://www.instagram.com/quarkconsult7/" title="Instagram">
            <i className="fa-brands fa-instagram"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="https://twitter.com/FinanceQuark" title="Instagram">
            <i className="fa-brands fa-linkedin-in"></i>
        </a>
    </li>
</ul>
            </div>
        </div>
    </div>
</div> 
        </>

     
    )
}

export default TopHeader;