import React from "react";
import CountUp from 'react-countup';
export const Home = () => {
  return (
    <div>
      {/* banner section  start */}
                <div className="tp-hero-area tp-hero-five__ptb-5 p-relative grey-bg-2 fix">
                <div className="container">
                    <div className="anim_line dark_bg">
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                        <span><img src="img/anim_line.png" alt="anim_line" /></span>
                    </div>
                </div>
                <div className="tp-hero-five__shape-2">
                    <img src="img/hero-circle-5-1.png" alt="" />
                </div>
                <div className="tp-hero-five__shape-3">
                    <img src="img/hero-circle-5-2.png" alt="" />
                </div>
                <div className="tp-hero-five__shape-4">
                    <img src="img/hero-circle-5-3.png" alt="" />
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6 order-2">
                            <div className="tp-hero-five-section-wrap">
                                <div className="tp-hero-five-section-box z-index">
                                    <h3 className="tp-hero-title-5 hero-text-anim-2">
                                        <i><i className="child-2"
                                        >Offer
                                            We are boutique <span className="child-1 p-relative">Financial Consulting

                                            </span>
                                            firm founded in 2012 by experienced professionals with diverse background providing Business and <span
                                                className="child-2">Financial Consulting</span> services to startups, small sized companies and individuals.
                                        </i></i>


                                    </h3>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="tp-hero-five-2-thumb-main p-relative">
                    <div className="tp-hero-five-2-thumb">
                        <img className="tp-hero-five-2-thumb-inner" src="img/financial-services-banner.jpg" alt="" />
                    </div>


                </div>
                </div>
                {/* banner section  end  */}
                {/* short description section start  */}
                <section className="" data-aos="fade-up">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-4 d-flex">
                                <div className="pricing_block blockwhite">
                                    <div className="icon">
                                        <img src="img/expertModeller.png" alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Expert Modeller</h3>

                                    </div>
                                    <p>
                                        Wall street grade modellers with rich experience across sectors
                                    </p>


                                </div>
                            </div>

                            <div className="col-md-4 d-flex">
                                <div className="pricing_block blockwhite">
                                    <div className="icon">
                                        <img src="img/collaborative.png" alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Collaborative</h3>

                                    </div>
                                    <p>
                                        Brainstorming approach involving all key stakeholders
                                    </p>


                                </div>
                            </div>

                            <div className="col-md-4 d-flex">
                                <div className="pricing_block blockwhite">
                                    <div className="icon">
                                        <img src="img/complexity.png" alt="image" />
                                        <div className="dot_block">
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                            <span className="dot_anim"></span>
                                        </div>
                                    </div>
                                    <div className="pkg_name">
                                        <h3>Complexity</h3>

                                    </div>
                                    <p>
                                        Capabilities to work on complex models with multiple scenarios and output dynamics
                                    </p>


                                </div>
                            </div>

                        </div>
                    </div>


                </section>
                {/* short description section end  */}
{/* aboutus section start  */}
                <section className="grayBG" data-aos="fade-up">
<div className="container">
    <div className="row">
        <div className="col-xl-6 col-lg-6 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".2s"
            >
            <div className="tp-about__img-wrapper text-center text-lg-end p-relative">
                <div className="tp-about__bg-shape">
                    <img src="img/about-bg-shape.png" alt=""/>
                </div>
                <div className="tp-about__main-img z-index">
                    <img src="img/about-2.jpg" alt=""/>
                </div>
                <div className="tp-about__sub-img-1 d-none d-sm-block z-index-3">
                    <img src="img/about-1.jpg" alt=""/>
                </div>
                <div className="tp-about__sub-img-2 d-none d-sm-block">
                    <img src="img/about-3.jpg" alt=""/>
                </div>
                <div className="tp-about__sub-img-3 d-none d-sm-block z-index-3">
                    <img src="img/about-5.png" alt=""/>
                </div>
            </div>
        </div>
<div className="col-xl-6 col-lg-6 ps-5">
    <h2 className="sectionTitle">
        <span>About</span> Us
    </h2>
    <p>
        Quark financials is engaged in providing high quality financial analysis support, research and intelligence solutions to
        global clientele.
        <br/><br/>
        We are trusted partners of many global clients over multiple years solving their financial challenges and enabling their
        growth by providing them with a flexible research + intelligence platform
    </p>

    <div className="testimonial-content">
        <div className="testimonial-quote"><i className="fa-light fa-quote-right"></i>
        </div>
        <p>Globally we are looking at continuous innovations in financial research & analytics and our focus is to adapt and
        leverage the benefits of the same for our clients</p>
  
    </div>

        </div>
        </div>

                        </div>
                        

</section>

                {/* aboutus section end  */}


                {/* service section star   */}
                
<section className="service" data-aos="fade-in">
<div className="container">
    <div className="anim_line dark_bg">
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
        <span><img src="img/anim_line_2.png" alt="anim_line"/></span>
    </div>

<div className="row">
    <div className="col-md-12">
        <h2 className="sectionTitle borderWhite">
            <span>Our</span> Services
        </h2>
    </div>
</div>

    <div className="row">
        <div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
            <div className="feature_block pricing_block blockwhite">
                <div className="icon">
                    <img src="img/richExperience.png" alt="image"/>
                    <div className="dot_block">
                        <span className="dot_anim"></span>
                        <span className="dot_anim"></span>
                        <span className="dot_anim"></span>
                    </div>
                </div>
                <div className="text_info">
                    <h3>Rich Experience</h3>
                    <p>Combined experience of more than 20 years in diverse background.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
        <div className="feature_block pricing_block blockwhite">
            <div className="icon">
                <img src="img/OntimeDelivery.png" alt="image"/>
                <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                </div>
            </div>
                <div className="text_info">
                    <h3>On time Delivery</h3>
                    <p>Ensures that every deadline is met and well communicated in advance.</p>
                </div>
            </div>
        </div>
    <div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
        <div className="feature_block pricing_block blockwhite">
            <div className="icon">
                <img src="img/Integrity.png" alt="image"/>
                <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                </div>
            </div>
            <div className="text_info">
                <h3>Integrity</h3>
                <p>Works with full integrity and maintains full confidentiality of clients’ data</p>
            </div>
        </div>
    </div>
    <div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
    <div className="feature_block pricing_block blockwhite">
        <div className="icon">
            <img src="img/Support24.png" alt="image"/>
            <div className="dot_block">
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
            </div>
        </div>
            <div className="text_info">
                <h3>24×7 Support</h3>
                <p>Ready to support with timely response across time zones.</p>
            </div>
        </div>
    </div>

<div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
<div className="feature_block pricing_block blockwhite">
    <div className="icon">
        <img src="img/TopQuality.png" alt="image"/>
        <div className="dot_block">
            <span className="dot_anim"></span>
            <span className="dot_anim"></span>
            <span className="dot_anim"></span>
        </div>
    </div>
        <div className="text_info">
            <h3>Top Quality</h3>
            <p>Ready to support with timely response across time zones..</p>
        </div>
    </div>
</div>
<div className="col-lg-6 col-md-6 aos-init" data-aos="fade-up" data-aos-duration="1500">
    <div className="feature_block pricing_block blockwhite">
        <div className="icon">
            <img src="img/CompetitivePricing.png" alt="image"/>
            <div className="dot_block">
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
            </div>
        </div>
        <div className="text_info">
            <h3>Competitive Pricing</h3>
            <p>Budget friendly with pricing on fixed and hourly basis</p>
        </div>
    </div>
</div>


    
    </div>



</div>


</section>


 {/* service section end  */}



               {/* counter section start   */}
                <section className="counter mt-5 mb-5" >
    <div className="container">

        <div className="row align-items-center">
            <div className="col-md-3">
                <div className="cointerBox afterNone">
                    <div className="p-relative">
 <CountUp className="count"
          start={0}
          end={2000}
          duration={15}
        /> 
                        {/* <div className="count">2000 </div> */}
                    </div>
                    <div className="cointerTitle">Hours of Work Done</div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="cointerBox">
            
                                    <div className="p-relative">
                                        <CountUp className="count"
          start={0}
          end={300}
          duration={15}
        /> 
                        {/* <div className="count">300 </div> */}
                    </div>
                    <div className="cointerTitle">Jobs Done</div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="cointerBox">
                 
                    <div className="p-relative">
                    <CountUp className="count"
          start={0}
          end={80}
          duration={15}
        /> 
            {/* <div className="count">80 </div> */}
                    </div>
                    <div className="cointerTitle">Clients</div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="cointerBox">
                    
                    <div className="p-relative">
                      <CountUp className="count"
          start={0}
          end={12}
          duration={15}
        /> 
          {/* <div className="count">12 </div> */}
                    </div>
                    <div className="cointerTitle">Employees</div>
                </div>
            </div>


                        </div>
                        
                        </div>

</section>
                {/* counter section ens  */}
    </div>
  );
};
