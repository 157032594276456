// import "rsuite/dist/rsuite.min.css";
import React, { useState, useEffect } from "react";

// import { Dropdown } from 'primereact/dropdown';
// import Select from 'react-select';
// import { DatePicker } from "rsuite";
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/primereact.min.css';
// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];


//    const datepickerStyle = { 
//         marginBottom: "25px", 
//         marginTop: "10px" 
//    };
    
export const Contact = () => {
    const [data,setData]=useState([]) 
    useEffect(() =>{
   

    fetch("https://reqres.in/api/users?page=2").then((result) => {
        result.json().then((resp) => {
        //   console.warn("result",resp)  
setData(resp)
        })
    
})


},[])

    console.warn(data)


// const [selectedCity, setSelectedCity] = useState(null);
//     const cities = [
//         { name: 'New York', code: 'NY' },
//         { name: 'Rome', code: 'RM' },
//         { name: 'London', code: 'LDN' },
//         { name: 'Istanbul', code: 'IST' },
//         { name: 'Paris', code: 'PRS' }
//     ];


    // const [selectedOption, setSelectedOption] = useState(null);
  return (
      <div>
       
     



           {/* page heading title section start  */}
<div className="about-banner-area p-relative">
    <div className="about-shape-1 z-index-3">
        <img src="img/breadcrumb-shape-1.png" alt=""/>
    </div>
    <div className="about-shape-2 z-index-3">
        <img src="img/breadcrumb-shape-2.png" alt=""/>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
               <div className="pageTitle"> Contact Us</div>
            </div>
        </div>
    </div>

</div>

 {/* page heading title section end  */}

{/* aboutus section start  */}
<section>
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12 mb-2">
                <h2 className="sectionTitle">
                    <span>Get In Touch  </span> With Us
                </h2>
            </div>
        </div>
<div className="row">
    <div className="col-lg-4">
        <div className="pel-address">
            <ul>
                <li>
                    <div className="icon">
                        <i className="flaticon-call"></i>
                    </div>
                    <p>Phone:</p>
                    <a href="tel:018823456789">+91 9826092090</a>
                </li>
            </ul>
        </div>

        <div className="pel-address">
            <ul>
                <li>
                    <div className="icon">
                        <i className="flaticon-email"></i>
                    </div>
                    <p>Email:</p>
                    <a href="mailto:contact@consultquark.com">contact@consultquark.com</a>
                </li>
            </ul>
        </div>

        <div className="pel-address">
            <ul>
                <li>
                    <div className="icon">
                        <i className="flaticon-address"></i>
                    </div>
                    <p>Location:</p>
                    <span>SH-69,HIG,Vijay Nagar Indore, India 452010</span>
                </li>
            </ul>
        </div>
    </div>
    <div className="col-lg-8">
        <form className="contact-form">
                              <div className="row">
                                  {/* <div className="col-md-6"> */}
                           {/* 
            <DatePicker style={datepickerStyle} /> */}
          
            {/* <DatePicker block style={datepickerStyle}
                placeholder="From" />             */}
                                  {/* </div> */}

                                  {/* <div className="col-md-6">
                                       <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
      />
</div> */}

                                  {/* <div className="col-md-6">
                                       <div className="card flex justify-content-center">
            <span className="p-float-label">
                <Dropdown inputId="dd-city" value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" className="w-full md:w-14rem" />
                <label htmlFor="dd-city">Select a City</label>
            </span>
        </div>
                                  </div> */}

                                  <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                                      
                     <div className="form-group">
                        <input type="text" className="form-control" autoComplete="off"  name="name" id="name" 
                            placeholder="Name"/>

                        <div className="help-block with-errors"></div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                        <input type="email" className="form-control" autoComplete="off"  name="email" id="email"
                            placeholder="Email"/>

                        <div className="help-block with-errors"></div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                        <input type="number" className="form-control" autoComplete="off"  name="phone" id="phone"
                            placeholder="Phone"/>

                        <div className="help-block with-errors"></div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                        <input type="text" required="" data-error="Please enter your subject" className="form-control"
                            placeholder="Subject"/>

                        <div className="help-block with-errors"></div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <textarea className="form-control textarea" cols="30" rows="8" required=""
                            data-error="Write your message" placeholder="Message"></textarea>

                        <div className="help-block with-errors"></div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <button className="button type3 themeBtn" type="submit">Submit</button>

                    
                </div>
            </div>
        </form>
    </div>
</div>

    </div>
    
</section>

 {/* aboutus section end   */}

 {/* map section start   */}
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29434.714702509697!2d75.85851395976063!3d22.752784738078248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302aa3765b19b%3A0xc7e0c53a7a214df!2sHig%2C%20Vijay%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1682575182760!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

 {/* map section end   */}
    </div>
  );
};
