import React from "react";
import $ from 'jquery'; 
import { useEffect } from "react";


function Footer() {

 const today = new Date();
  const year = today.getFullYear();

        useEffect(() => {
    $(window).scroll(function () {
        if ($(document).scrollTop() == 0) {
          $('.wowmenu').removeClass('tiny');
        } else {
          $('.wowmenu').addClass('tiny');
        }
    });
            
            
  },[]);
    return (
          <>
             <section className="footerTop">
       <div className="container" >
        <div className="row">
            <div className="col-md-6 col-lg-3 tabMb-5">
              <div className="whiteBg mb-3"> <img src="img/logo.png"/></div>
<p>
    We are dynamic team of talented and experienced individuals
    helping organizations in meeting their digital needs
</p>
<ul className="socialIcon flexStart">
    <li>
        <a className="" target="blank" href="https://www.facebook.com/profile.php?id=100086482462173" title="Facebook">
            <i className="fa-brands fa-facebook-f"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="#" title="Pinterest">
            <i className="fa-brands fa-twitter"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="https://www.instagram.com/quarkconsult7/" title="Instagram">
            <i className="fa-brands fa-instagram"></i>
        </a>
    </li>
    <li>
        <a className="" target="blank" href="https://twitter.com/FinanceQuark" title="Instagram">
            <i className="fa-brands fa-linkedin-in"></i>
        </a>
    </li>
</ul>
            </div>
<div className="col-md-1 tabMob"></div>
<div className="col-md-6 col-lg-2 tabMb-5">
<div className="footerTitle">
    Information
</div>
<div className="textwidget">
    <ul>
        <li><a href="#">About Us</a></li>
        <li><a href="#">Services</a></li>
        <li><a href="#">Contact</a></li>
    </ul>
</div>

</div>

<div className="col-md-6 col-lg-3">
    <div className="footerTitle">
        Contact us
    </div>
  <div className="d-flex align-items-center">
<div className="footerIcon">
<i class="fa-light fa-location-dot"></i>
</div>
<div className="disCription">
    Sh-69 hig vijay nagar indore, India 452010
</div>

  </div>

<div className="d-flex align-items-center">
    <div className="footerIcon">
        <i className="flaticon-open-email"></i>
    </div>
    <div className="disCription">
       <a href="mailto:contact@consultquark.com" className="whiteText"> contact@consultquark.com </a>

    </div>

</div>


</div>

<div className="col-md-6 col-lg-3">
    <div className="footerTitle">
        Subscribe
    </div>
    <p>
        Subscribe to get the latest news from us.
    </p>

    <div className="form-fields">
        <input type="email" name="EMAIL" placeholder="Enter Your Email Address"
            className="fill_inited"/>
        <button>Subscribe</button>
    </div>

</div>

        </div>
       </div>
    </section>
<div className="copyRight">
<div className="container">
    <div className="row d-flex">
        <div className="col-md-6">
Copyright © {year} quarkfinancials. All rights reserved.
            </div>

<div className="col-md-6">
    <ul className="footerBottom">
        <li>
            <a className="" target="blank" href="#" title="">
            Terms Of Service
            </a>
        </li>
        <li>
            <a className="" target="blank" href="#" title="">
                Privacy Policy
            </a>
        </li>
        <li>
            <a className="" target="blank" href="#" title="">
            Legal
            </a>
                                    </li>
                                    </ul>
</div>


            </div>
            </div>
                </div>
                </>
    )
}

export default Footer;
