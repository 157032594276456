import "./App.css";
import "../src/css/style.css";
import "../src/css/animate.css";
import "../src/css/animation.css";
import "../src/css/font.css";
// import "../src/css/newMenu.css";
import "../src/css/responsive.css";
import "../src/fonts/flaticons.min.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Ourservice } from "./components/Pages/Ourservice";
import { Contact } from "./components/Pages/Contact";
import TopHeader from "./components/TopHeader";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Router>
        <TopHeader/>
        <NavBar />

        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/ourservice" element={<Ourservice />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </Router>
      <Footer/>
  </>
  );
}

export default App;
