import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export const Ourservice = () => {
  return (
    <div>
      {/* page heading title section start  */}
<div className="about-banner-area p-relative">
    <div className="about-shape-1 z-index-3">
        <img src="img/breadcrumb-shape-1.png" alt=""/>
    </div>
    <div className="about-shape-2 z-index-3">
        <img src="img/breadcrumb-shape-2.png" alt=""/>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
               <div className="pageTitle"> Our Services</div>
            </div>
        </div>
    </div>

</div>

 {/* page heading title section end   */}

 {/* aboutus section start  */}

<section>
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12 mb-2">
                <h2 className="sectionTitle">
                    <span>What Service </span> We Offer
                </h2>
                <p >
                   <b> We offer a wide array of services varying in scope in a time-bound budget-friendly manner.</b>
                </p>
            
               
                
            
            </div>

<div className="col-xl-6 col-lg-6 d-flex">
<div className="client_card pricing_block blockwhite">
    <div className="user_img">
        <div className="icon">
            <img src="img/FinancialModelling.png" alt="image"/>
            <div className="dot_block">
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
            </div>
        </div>
    </div>
    <div className="inf_content">
        <div className="subHeading">Financial Modelling</div>
    Proficient in creating financial models for companies across industries and domain varying in complexity.
    </div>
</div>
</div>
<div className="col-xl-6 col-lg-6">
<div className="client_card pricing_block blockwhite">
    <div className="user_img">
        <div className="icon">
            <img src="img/StartupAssistance.png" alt="image"/>
            <div className="dot_block">
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
                <span className="dot_anim"></span>
            </div>
        </div>
    </div>
    <div className="inf_content">
        <div className="subHeading">Startup Assistance</div>
    Provide assistance to start-ups including financial projections, creating pitch decks, business plan formulation,
    conducting market research and fund-raising support.
    </div>
</div>
</div>

<div className="col-xl-6 col-lg-6">
    <div className="client_card pricing_block blockwhite">
        <div className="user_img">
            <div className="icon">
                <img src="img/DataAnalytics.png" alt="image"/>
                <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                </div>
            </div>
        </div>
        <div className="inf_content">
            <div className="subHeading">Data Analytics And Business Intelligence</div>
        Well versed in advanced data analytics tool such as MS Access, SQL, SPSS and SAS with good understanding of business
        scenarios.
        </div>
    </div>
</div>
<div className="col-xl-6 col-lg-6">
    <div className="client_card pricing_block blockwhite">
        <div className="user_img">
            <div className="icon">
                <img src="img/Administrative.png" alt="image"/>
                <div className="dot_block">
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                    <span className="dot_anim"></span>
                </div>
            </div>
        </div>
        <div className="inf_content">
            <div className="subHeading">Administrative and Accounting Support</div>
            Team of experienced English speaking accountants with expertise in accounting software such as Quickbooks, Zoho and
            Xero.
        </div>
    </div>
</div>

        </div>
    </div>
    
</section>

 {/* aboutus section end   */}


 {/* testimonial section start  */}
  <div className="all_testimonial_area  section-padding content-center pt-5" data-aos="fade-up">
			<div className="container">
				<div className="all_testimonial_container">
					<div className="row">
						<div className="col-md-12">
							<div className="section_title nice_title_white">
                                <h2 className="sectionTitle">
                                    <span>Our</span> Testimonials
                                </h2>
								<p className="">
                                    What People Say About Us
								</p>
							</div>
						</div>
                            </div>
                            
<div className="row">
						<div className="col-md-12">
 <Carousel>
        <div>
             <div class="sp_testimonial_section">
            <p class="sp_padding_bottom_30">They are very professional with high quality of work</p>
                                                <h5 class="text-right">Anand Nagaraj</h5>
                       <h6><span className="testimonial-position">Invoice Bazaar, UAE</span>  </h6>           
            <div class="sp_test_img">
                <img src="img/profile.png" alt="" class="sp_radius"/>
            </div>
        </div>
        </div>
        <div>
             <div class="sp_testimonial_section">
            <p class="sp_padding_bottom_30">Quark were great, polite, quick and did exactly what I asked for.</p> 
                                                <h5 class="text-right">Jared Kogan</h5>
                       <h6><span className="testimonial-position">Giggle Finance</span>  </h6>           
            <div class="sp_test_img">
                <img src="img/profile.png" alt="" class="sp_radius"/>
            </div>
        </div>
        </div>
   <div>
             <div class="sp_testimonial_section">
            <p class="sp_padding_bottom_30">Nice to work with, friendly and good on deadlines.</p>
           <h5 class="text-right">Maurice Kroon</h5>
                       <h6><span className="testimonial-position">Lean Toolkit BV</span>  </h6>           
            <div class="sp_test_img">
                <img src="img/profile.png" alt="" class="sp_radius"/>
            </div>
        </div>
        </div>
  <div>
             <div class="sp_testimonial_section">
            <p class="sp_padding_bottom_30">Great company to work with, thank you Quark Financials for making Financial Model for me. Look forward to working with
                        you again.</p>
                                                <h5 class="text-right">Saleh Alwallah</h5>
                       <h6><span className="testimonial-position">Controllex, Saudi Arabia</span>  </h6>           
            <div class="sp_test_img">
                <img src="img/profile.png" alt="" class="sp_radius"/>
            </div>
        </div>
        </div>
      </Carousel>

                                </div>
                                </div>
                            


                    
			
				</div>
			</div>
		</div>




 {/* testimonial section end  */}
    </div>
  );
};
